import cLogger from '@helpers/logger/conditionalLogger'
import GetCurrencyCode from '@i18n/getCurrencyCode'
import {
  AnalyticsService,
  ImplementationOptions,
} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import useInitialData from '@page-components/Layout/useInitialData'
import useLoadUserInfo from '../../hooks/useLoadUserInfo'
import {ERROR_INITIALIZING, ERROR_SENDING_EVENT} from '../errorMessages'

export const ANALYTICS_BRAZE = 'braze'

interface BrazeImplementationConfiguration {
  verbose: boolean
  isEnabled: boolean
  currencyCode: string
  brazeApiKey: string
  brazeEndpoint: string
  userInfoPromise: any
}

class BrazeImplementation implements AnalyticsService {
  private verbose: boolean
  private currencyCode: string
  private brazeApiKey: string
  private brazeEndpoint: string
  private userInfoPromise: any

  public name = ANALYTICS_BRAZE
  public isInternal = true
  public isEnabled: boolean

  constructor(conf: BrazeImplementationConfiguration) {
    this.verbose = conf.verbose
    this.isEnabled = conf.isEnabled
    this.currencyCode = conf.currencyCode
    this.brazeApiKey = conf.brazeApiKey
    this.brazeEndpoint = conf.brazeEndpoint
    this.userInfoPromise = conf.userInfoPromise
  }

  async initialize() {
    if (!this.isEnabled) return

    await import('@braze/web-sdk')
      .then(({initialize, openSession}) => {
        initialize(this.brazeApiKey, {
          baseUrl: this.brazeEndpoint,
          enableLogging: this.verbose,
        })
        openSession()
      })
      .catch(err => {
        cLogger(this.verbose).warn(ERROR_INITIALIZING(ANALYTICS_BRAZE), {err})
      })
  }

  async sendEventToBraze(eventName: string, data?: any) {
    if (!this.isEnabled) return

    const logEvent = async () => {
      try {
        const braze = await import('@braze/web-sdk')
        if (eventName === 'purchase') {
          braze.logPurchase('order_completed', data.price, data.currency, 1, data.orderProperties)
        } else {
          braze.logCustomEvent(eventName, data)
        }
      } catch (err) {
        cLogger(this.verbose).warn(ERROR_SENDING_EVENT(ANALYTICS_BRAZE), {err})
      }
    }

    await logEvent()
  }

  async trackProductView(product) {
    if (!this.isEnabled) return
    return this.sendEventToBraze('product_selected', {
      product_id: product._id,
      product_name: product.name,
      product_price: product.value,
      currency: this.currencyCode,
    })
  }

  async trackAddToCart(productWithState) {
    if (!this.isEnabled) return
    const product = productWithState
    return this.sendEventToBraze('add_to_cart', {
      currency: this.currencyCode,
      products: [
        {
          product_id: product._id,
          product_name: product.name,
          product_price: product.availabilityAt?.finalPrice,
        },
      ],
    })
  }

  async trackInitCheckout(preferences) {
    if (!this.isEnabled) return
    return this.sendEventToBraze('initiate_checkout', {
      totalPrice: preferences?.cart.amountToPay,
      currency: this.currencyCode,
      products: preferences?.cart?.items?.map(item => ({
        product_id: item?.product?._id,
        product_name: item?.product?.name,
        price: item?.unitPrice,
        quantity: item?.amount,
      })),
    })
  }

  async trackPurchase(order) {
    const orderProperties = {
      transaction_id: order._id,
      products: order.items.map(item => ({
        product_id: item?.product?._id,
        product_name: item?.product?.name,
        product_price: item?.unitPrice,
        product_amount: item?.amount,
      })),
    }

    await this.sendEventToBraze('purchase', {
      currency: this.currencyCode,
      price: order.totalPrice.toString(),
      orderProperties,
    })

    if (order.extraProperties) {
      const {websiteCoinsBalance} = order.extraProperties
      await this.setCustomAttributes(websiteCoinsBalance, 'points')
    }
  }

  async trackScreenView(_properties) {
    //This is not needed for Braze.
  }

  async trackEventAsDefault(_eventType: string, _properties?: any) {
    //This is not needed for Braze.
  }

  async setUserId(userId: string) {
    if (!this.isEnabled || !userId) return
    try {
      const userInfo = await this.userInfoPromise
      const braze = await import('@braze/web-sdk')

      braze.changeUser(userId)
      if (!userInfo) {
        return cLogger(this.verbose).warn(
          `The user with the id ${userId} does not have data for ${ANALYTICS_BRAZE}`,
        )
      }
      this.updateUserData(braze, userInfo)
    } catch (err) {
      cLogger(this.verbose).warn(`Error setting setUserId on ${ANALYTICS_BRAZE}`, err)
    }
  }

  async setCustomAttributes(attributes: any, name: string) {
    if (!attributes) return
    try {
      const braze = await import('@braze/web-sdk')
      braze.getUser().setCustomUserAttribute(name, attributes)
    } catch (err) {
      cLogger(this.verbose).warn(
        `Error setting custom attributes for ${name} on ${ANALYTICS_BRAZE}`,
        err,
      )
    }
  }

  async updateUserData(braze: any, userInfo: any) {
    braze.getUser().setFirstName(userInfo.profile.firstName)
    braze.getUser().setLastName(userInfo.profile.lastName)
    braze.getUser().setEmail(userInfo.email)
  }
}

let BrazeImplementationSingleton: BrazeImplementation

function useBrazeImplementation(options: ImplementationOptions): AnalyticsService {
  const verbose = options.verbose ?? false
  const initialData = useInitialData()
  const {integrations: initialConfig} = initialData
  const brazeApiKey = initialConfig?.integrations?.braze?.apiKey
  const brazeEndpoint = initialConfig?.integrations?.braze?.endpoint
  const isEnabled = !!brazeApiKey && !!brazeEndpoint
  const currencyCode = GetCurrencyCode()
  const userInfoPromise = useLoadUserInfo(isEnabled)

  const config = {
    verbose,
    isEnabled,
    currencyCode,
    brazeApiKey,
    brazeEndpoint,
    userInfoPromise,
  }

  if (BrazeImplementationSingleton) {
    return BrazeImplementationSingleton
  }

  BrazeImplementationSingleton = new BrazeImplementation(config)
  return BrazeImplementationSingleton
}

export default useBrazeImplementation
