import cLogger from '@helpers/logger/conditionalLogger'
import isScrapping from '@helpers/misc/isScrapping'
import isServerSide from '@helpers/misc/isServerSide'
import useCookiesAccepted from '@hooks/useCookiesAccepted'
import getEnv from '@providers/getEnv'
import {useMemo} from 'react'
import {useOnEvent} from 'react-app-events'
import useAmplitudeImplementation from '../implementations/amplitude'
import useBrazeImplementation from '../implementations/braze'
import useGA4Implementation from '../implementations/google/ga4'
import useGTMImplementation from '../implementations/google/gtm'
import useMetaImplementation from '../implementations/meta'
import {AnalyticsService, EcommerceEvent, InternalEvent} from '../types/analyticsServicesTypes'
import useIsAnalyticsV2Avail from './useIsAnalyticsV2Available'

export interface AnalyticsServicesControllerType {
  initializeAll(): Promise<void>
  setUserIdAll(userId: string): Promise<void>
  trackProductViewOnAll(
    product: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackAddToCartOnAll(
    productWithState: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackEventOnAll(event: EcommerceEvent, properties: Record<string, any>): Promise<void>
  trackEventAsDefaultOnInternal(
    event: InternalEvent,
    properties?: Record<string, any>,
  ): Promise<void>
  trackScreenViewAll(): Promise<void>
  trackInitCheckoutOnAll(
    cart: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackPurchaseOnAll(
    order: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
}

class AnalyticsController implements AnalyticsServicesControllerType {
  private allImplementations: {[key: string]: AnalyticsService}
  private internalImplementations: {[key: string]: AnalyticsService}
  private verbose: boolean
  public isEnabled: boolean

  constructor(implementations: AnalyticsService[], isEnabled: boolean) {
    this.verbose = false
    const implementationsObject: {[key: string]: AnalyticsService} = implementations.reduce(
      (acc, service) => {
        acc[service.name] = service
        return acc
      },
      {},
    )
    this.allImplementations = Object.fromEntries(
      Object.entries(implementationsObject).filter(
        ([, implementation]) => implementation.isEnabled,
      ),
    )
    this.internalImplementations = Object.fromEntries(
      Object.entries(this.allImplementations).filter(
        ([, implementation]) => implementation.isInternal,
      ),
    )
    this.isEnabled = isEnabled && Object.keys(this.allImplementations).length > 0
  }

  async initializeAll() {
    for (const implementation of Object.values(this.allImplementations)) {
      if (!implementation.isEnabled) continue
      await implementation.initialize()
    }
  }

  async trackEventOnAll(eventName: EcommerceEvent, properties: Record<string, any>): Promise<void> {
    if (!this.isEnabled) return
    cLogger(this.verbose).info(`Tracking ${eventName} for all`, properties)
    for (const implementation of Object.values(this.allImplementations)) {
      await this.trackEventOn(implementation.name, eventName, properties)
    }
  }

  async trackEventOn(
    implName: string,
    eventName: string,
    properties: Record<string, any>,
  ): Promise<void> {
    if (!this.isEnabled) return
    try {
      await this.allImplementations[implName][`track${eventName}`](properties)
      cLogger(this.verbose).info(
        `Tracked event ${eventName} on ${this.allImplementations[implName].name}`,
      )
    } catch (err) {
      cLogger(this.verbose).warn(`Error tracking event ${eventName} for ${implName}`, err)
    }
  }

  async trackEventAsDefaultOnInternal(eventName: InternalEvent, properties?: Record<string, any>) {
    if (!this.isEnabled) return
    cLogger(this.verbose).info(
      `Tracking ${eventName} as default on internal implementations`,
      properties,
    )
    for (const impl of Object.values(this.internalImplementations)) {
      await this.trackEventAsDefaultOn(impl.name, eventName, properties)
    }
  }

  async trackEventAsDefaultOn(
    implName: string,
    eventName: string,
    properties: Record<string, any>,
  ): Promise<void> {
    if (!this.isEnabled) return
    try {
      await this.allImplementations[implName].trackEventAsDefault(eventName, properties)
      cLogger(this.verbose).info(
        `Tracked event ${eventName} as default on ${this.allImplementations[implName].name}`,
      )
    } catch (err) {
      cLogger(this.verbose).warn(
        `Error tracking event ${eventName} as default for ${implName}`,
        err,
      )
    }
  }

  async trackProductViewOnAll(product: any, extraProperties?: any) {
    if (!product?.availabilityAt) return
    await this.trackEventOnAll('ProductView', {...product, extraProperties})
  }

  async trackAddToCartOnAll(product: any, extraProperties?: any) {
    if (!product?.availabilityAt) return
    await this.trackEventOnAll('AddToCart', {...product, extraProperties})
  }

  async trackInitCheckoutOnAll(preferences: any, extraProperties?: any) {
    await this.trackEventOnAll('InitCheckout', {...preferences, extraProperties})
  }

  async trackPurchaseOnAll(order: any, extraProperties?: any) {
    if (!order) return
    await this.trackEventOnAll('Purchase', {...order, extraProperties})
  }

  async trackScreenViewAll() {
    //Tracking each page is complex, it has a different default behaviour by implementation. See each implementation for details.
    return
  }

  async setUserIdAll(userId: string): Promise<void> {
    if (!this.isEnabled || !userId) return
    cLogger(this.verbose).info(`Setting up for all userId ${userId}`)
    // Only available for Internal trackers (Amplitude so far)
    try {
      for (const impl of Object.values(this.internalImplementations)) {
        await impl.setUserId(userId)
      }
    } catch (err) {
      cLogger(this.verbose).warn('Error setting setUserId on Internal', err)
    }
  }
}

export const ANALYTICS_V2_VERBOSE = false // Set verbose to true to debug
export const ANALYTICS_V2_FORCE = false // Set force to true to run in local
export default function useLazyAnalyticsV2() {
  const env = getEnv()
  const isAnalyticsV2Available = useIsAnalyticsV2Avail()
  const amplitude = useAmplitudeImplementation({verbose: ANALYTICS_V2_VERBOSE})
  const ga4 = useGA4Implementation({verbose: ANALYTICS_V2_VERBOSE})
  const meta = useMetaImplementation({verbose: ANALYTICS_V2_VERBOSE})
  const gtm = useGTMImplementation({verbose: ANALYTICS_V2_VERBOSE})
  const braze = useBrazeImplementation({verbose: ANALYTICS_V2_VERBOSE})
  const implementations = [amplitude, ga4, gtm, meta, braze]
  const isEnabled =
    (useCookiesAccepted() && !isServerSide() && !isScrapping && ['prod', 'dev'].includes(env)) ||
    ANALYTICS_V2_FORCE

  const analyticsControllerMemo = useMemo(() => {
    return new AnalyticsController(implementations, isEnabled)
  }, [])

  useOnEvent('updateAnalyticsUserId', async (newUserId: string) => {
    await analyticsControllerMemo.setUserIdAll(newUserId)
  })

  if (!isAnalyticsV2Available || !isEnabled) {
    cLogger(ANALYTICS_V2_VERBOSE).warn('Lazy Analytics V2 called without being enabled')
    return {
      implementations: [],
      enabled: false,
      initializeAll: async () => {},
      setUserIdAll: async () => {},
      trackEventAsDefaultOnInternal: async () => {},
      trackEventOnAll: async () => {},
      trackScreenViewAll: async () => {},
      trackProductViewOnAll: async () => {},
      trackAddToCartOnAll: async () => {},
      trackInitCheckoutOnAll: async () => {},
      trackPurchaseOnAll: async () => {},
    }
  }

  return analyticsControllerMemo
}
